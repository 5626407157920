<template>
  <div class="app-form">
    <el-form
      ref="formFpostgrad"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <el-row>
        <el-col :span="12">
          <!-- Médecin -->
          <el-form-item label="Médecin" prop="id_med" v-show="!ismeddetail">
            <el-select
              v-model="form.id_med"
              clearable
              filterable
              placeholder="Choisissez un médecin"
            >
              <el-option
                v-for="item in medecinOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- Type -->
          <el-form-item label="Type" prop="type">
            <el-radio-group
              v-model="type"
              size="small"
              style="width: 300px"
              @change="resetTitre"
            >
              <el-radio-button
                v-for="opt in catOptions"
                :key="opt.value"
                :label="opt.value"
                >{{ opt.label }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <!-- Titre -->
          <el-form-item label="Titre" prop="id_fpost_titre">
            <el-select
              ref="titre"
              v-model="form.id_fpost_titre"
              :disabled="desactive"
              clearable
              filterable
              placeholder="Choisissez un titre"
              style="width: 300px"
              @change="changeTitre"
            >
              <el-option
                v-for="item in titreOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- Précisions -->
          <el-form-item label="Précisions" prop="precisions">
            <el-input
              v-model="form.precisions"
              :disabled="desactive"
            ></el-input>
          </el-form-item>
          <!-- Utiliser la checklist -->
          <el-form-item
            prop="use_checklist"
            v-show="[71, 1, 2].includes(form.id_fpost_titre)"
          >
            <el-switch
              v-model="form.use_checklist"
              :active-value="1"
              :inactive-value="0"
              active-text="Utiliser la checklist"
              @change="changeUseChecklist"
            ></el-switch>
          </el-form-item>
          <!-- Statut -->
          <el-form-item prop="id_statut" label="Statut" v-show="statutShow">
            <el-radio-group
              v-model="form.id_statut"
              size="small"
              style="width: 280px"
              :disabled="desactive || statutDesactif"
              @change="anneeReset"
            >
              <el-radio-button :label="1">prévue</el-radio-button>
              <el-radio-button :label="2">en cours</el-radio-button>
              <el-radio-button :label="3">terminée</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <!-- Année -->
          <el-form-item label="Année" prop="annee" v-show="form.id_statut == 3">
            <el-select
              ref="annee"
              size="small"
              v-model="form.annee"
              :disabled="desactive"
              clearable
              filterable
              placeholder="Choisissez une annnée"
            >
              <el-option
                v-for="item in anneeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- -->
          <p class="updated-at" v-show="form.updated_at">
            Dernière maj : {{ this.form.updated_at }}
          </p>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { objGetNested } from "@/fonctions";
export default {
  data() {
    var valideTitre = (rule, value, callback) => {
      if (this.$refs.titre.value) {
        // si statut = terminée
        callback();
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    var valideStatut = (rule, value, callback) => {
      if (this.isedit || (!this.edit && !this.statutDesactif)) {
        if ([1, 2, 3].includes(value)) {
          callback();
        } else {
          callback(new Error("Ce champ est obligatoire"));
        }
      } else {
        callback();
      }
    };
    var valideAnnee = (rule, value, callback) => {
      if (this.isedit && this.form.id_statut && this.form.id_statut == 3) {
        // si statut = terminée
        if (value) {
          callback();
        } else {
          callback(new Error("Ce champ est obligatoire"));
        }
      } else {
        callback();
      }
    };
    return {
      cats: [],
      titres: [],
      type: null,
      desactive: true,
      rules: {
        id_med: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_fpost_titre: [
          { required: true, validator: valideTitre, trigger: "change" },
        ],
        id_statut: [
          { required: true, validator: valideStatut, trigger: "change" },
        ],
        annee: [{ required: true, validator: valideAnnee, trigger: "change" }],
      },
    };
  },
  props: {
    ismeddetail: Boolean,
    isedit: Boolean,
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.fpg.fpostgrad;
      },
      set(form) {
        this.$store.commit("fpg/setFpostgrad", form);
      },
    },
    medecinOptions() {
      return this.$store.getters["medl/medecinSelect"];
    },
    catOptions() {
      return this.$store.getters["fpgcat/categorieSelect"];
    },
    titreOptions() {
      const titres = this.$store.getters["fpgtit/titreSelect"];
      return this.type ? titres.filter((o) => o.type == this.type) : [];
    },
    anneeOptions() {
      return this.$store.getters["fpg/optAnnee"];
    },
    statutShow() {
      return (
        this.isedit || // si pas form create
        (!this.isedit && ![71, 1, 2].includes(this.form.id_fpost_titre)) || // ou si form create mais pas les 3 titres avec checklist
        (!this.isedit && !this.statutDesactif)
      ); // ou si form create avec 1 des 3 titres à checklist mais avec option sans checklist
    },
    statutDesactif() {
      return [71, 1, 2].includes(this.form.id_fpost_titre) &&
        this.form.use_checklist == 1
        ? true
        : false;
    },
    fpgTitresIsLoaded() {
      return (
        this.$store.state.fpg.fpostgradLoadStatus == 2 &&
        this.$store.state.fpgtit.titresLoadStatus == 2
      );
    },
    idTitre() {
      return this.form.id_fpost_titre;
    },
  },

  methods: {
    setType() {
      const titres = this.$store.getters["fpgtit/titreSelect"];
      const idTitre = this.form.id_fpost_titre;
      if (idTitre) {
        // données de l'activité HC sélectionnée
        const titredata = titres.filter((a) => a.value == idTitre)[0];
        // récupère le id du type en évitant l'erreur "Cannot read property 'type' of undefined"
        this.type = objGetNested(titredata, ["type"]);
      }
      // active 'Activité' si un type est choisi
      this.desactive = this.type ? false : true;
    },
    resetTitre() {
      const opt = this.titreOptions;
      // si une seule option la sélectionnne
      const titre = opt.length == 1 ? opt[0].value : null;
      // vide le champs 'titre' sur changement de 'type'
      this.$refs.titre.$emit("input", titre);
      // active 'Titre' si un type est choisi
      this.desactive = this.type ? false : true;
    },
    anneeReset() {
      // supprime année sur changement de statut
      this.form.annee = "";
    },
    changeUseChecklist(val) {
      if (val == 1) {
        this.form.id_statut = null;
      }
    },
    changeTitre() {
      // si titre avec checklist, utilise la cl autrement pas par défaut
      this.form.use_checklist = [71, 1, 2].includes(this.form.id_fpost_titre)
        ? 1
        : 0;
    },
  },
  watch: {
    fpgTitresIsLoaded() {
      // watch le chargement de la fpg du médecin et aussi celui des fpostgrads si charge directement la page: 2 + 2
      if (this.fpgTitresIsLoaded) {
        this.setType();
      }
    },
    idTitre() {
      // maj le type de formation sélectionné quand annule changement de type
      this.setType();
    },
  },
};
</script>
