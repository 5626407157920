<template>
  <el-main class="rh-form">
    <form-fpostgradcreate :routes="fpostRoutes" />
  </el-main>
</template>
<script>
import FormFpostgradcreate from "@/components/rh/formulaires/FormFpostgradcreate";

export default {
  components: { "form-fpostgradcreate": FormFpostgradcreate },
  computed: {
    fpostRoutes() {
      return {
        toedit: {
          name: "fpostgradedit",
          params: { idfpg: this.$store.state.fpg.fpostgradLastCreatedId },
        },
        tolist: { name: "fpostgrads" },
      };
    },
  },
};
</script>
