<template>
  <div>
    <h1>Ajouter une formation post-graduée</h1>
    <form-fpostgrad
      :ismeddetail="ismeddetail"
      :isedit="false"
      ref="formFpost"
    />
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormFpostgrad from "@/components/rh/ui/form/FormFpostgrad";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-fpostgrad": FormFpostgrad },

  data() {
    return {
      isSaving: false,
    };
  },
  props: {
    ismeddetail: Boolean,
    routes: Object,
  },
  created() {
    // récupère l'id du médecin si dans détail médecin
    const idMed = this.$route.params.idmed;
    this.$store.commit("fpg/resetFpostgrad", idMed);
  },
  computed: {
    fpostgradCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fpg.fpostgradCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formFpost.$refs.formFpostgrad.validate((valid) => {
        if (valid) {
          var fpgdata = this.$store.state.fpg.fpostgrad;

          // enlever les espaces avant et après dans les string
          trimStringInObj(fpgdata);

          // vérifie si le titre existe déjà pour le médecin
          const fpgInBD = this.$store.getters["fpg/FpostgradInBD"];
          if (
            fpgInBD.filter(
              (m) => m[1] == fpgdata.id_med && m[2] == fpgdata.id_fpost_titre
            ).length > 0
          ) {
            this.$alert(
              "Ce titre a déjà été enregistré pour ce médecin",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si titre avec checklist et que use_checklist coché, met le statut à 1 = "prévue"
            if (
              fpgdata.use_checklist == 1 &&
              [71, 1, 2].includes(fpgdata.id_fpost_titre)
            ) {
              fpgdata.id_statut = 1;
            }
            // si plus d'erreur lance la création du nouvel enregistrement
            this.$store.dispatch("fpg/createFpostgrad", fpgdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des contrats
      this.$router.push(this.routes.tolist);
    },
  },
  watch: {
    fpostgradCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.fpostgradCreateStatus == 2) {
        // message succès
        this.$message({
          message: "La formation a été ajouté-e à la base de donnée",
          type: "success",
        });

        // va au formulaire d'édition de la fpg
        this.$router.push(this.routes.toedit);
      } else if (this.fpostgradCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.fpostgradCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.fpostgradCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
